import React from 'react'

import './Alineacion.scss'
import servicioImg from '../../assets/servicio.jpg'

const Alineacion = () => {
  return (
    <section className='servicio'>

        <div className="servicio-head">
          <h2>nuestros servicios</h2>
          <div className="divider"></div>
        </div>
        
        <div className="servicio-content">
            <div className="servicio-text">
                <h3>Alineación de vehículos menores, camiones y buses:</h3>
                <br />
                <p>Contamos con la tecnología más avanzada para ofrecerte servicios de alineación de alta calidad, lo que garantiza la estabilidad y seguridad en la carretera.</p>
            </div>
            <div className="servicio-image">
                <img src={servicioImg} alt="servicio" />
            </div>
        </div>

    </section>
  )
}

export default Alineacion