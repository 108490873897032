import React from 'react'
import {Link} from 'react-router-dom'

import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import truck from '../../assets/truck.jpg'

import './Services.scss'

const Services = () => {
  return (
    <section className='services'>
      <div className="services__head">
        <h2>nuestros servicios</h2>
        <div className="divider"></div>
      </div>

      <div className="services-content">
        <div className="services-text">

          <ul>
            <li><Link to="/alineacion" className='link'>Alineación vehículos menores, camiones y buses <ArrowOutwardIcon/></Link></li>
            <li><Link to="/balanceo" className='link'>Balanceo vehículos menores, camiones y buses <ArrowOutwardIcon/></Link></li>
            <li><Link to="/vulcanizacion" className='link'>Servicio de Vulcanización para vehículos menores y mayores <ArrowOutwardIcon/></Link></li>
            <li><Link to="/discos" className='link'>Rectificación de discos y tambor para vehículos medianos <ArrowOutwardIcon/></Link></li>
            <li><Link to="/revision" className='link'>Revisión y reparación de frenos para vehículos medianos <ArrowOutwardIcon/></Link></li>
            <li><Link to="/rectificacion" className='link'>Rectificación camber y coster <ArrowOutwardIcon/></Link></li>
            <li><Link to="/aceite" className='link'>Cambio de aceite para vehículos menores y mayores <ArrowOutwardIcon/></Link></li>
            <li><Link to="/scanner" className='link'>Servicio de Scanner <ArrowOutwardIcon/></Link></li>
            <li><Link to="/hidraulicos" className='link'>Cambio de flexibles hidráulicos <ArrowOutwardIcon/></Link></li>
          </ul>

        </div>
        <div className="services-image">
          <p>En Fast Track nos enfocamos en ofrecer soluciones innovadoras y audaces para resolver los problemas principales del mercado automotriz local, garantizando la satisfacción y seguridad de nuestros clientes. </p>
        </div>

      </div>

      <div className="services-content-2">
        <div className="services-image-2">
          <img src={truck} alt="truck" />
        </div>
        <div className="services-text-2">
          <p>
          ¡Contáctanos para agendar una cita y experimentar la diferencia en el servicio automotriz!
          </p>
        </div>
      </div>

    </section>
  )
}

export default Services
