import React from 'react'
import { Link } from 'react-router-dom';
import ReactWhatsapp from 'react-whatsapp';

import Car from '../../assets/car.png'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import './Header.scss'

const Header = () => {
  return (
    <div className='header' id='header'>
       {/* <div className='section__head'>
        <h2>Fast Track</h2>
      </div> */}
      <div className="header__container">
        <div className='header__container-text'>
          <h2>Fast Track</h2>
          <h3>Mecánica avanzada para un rendimiento superior</h3>
           <div className="header__container-text-btn">
           <button className='btn'><Link className='link' to="/services">Ver más</Link></button>
           <ReactWhatsapp id='wpp' number="+56 9 58555539" message="Hola, quisiera más información"><WhatsAppIcon/></ReactWhatsapp>
           </div>
        </div>
        <div className='header__container-img'>
          <Link to="/services"><img src={Car} alt="car" /></Link>
        </div>
      </div>

    </div>
  )
}

export default Header
