import React from 'react'

import servicioImg from '../../assets/servicio.jpg'

const Scanner = () => {
  return (
    <section className='servicio'>
    <div className="servicio-head">
      <h2>nuestros servicios</h2>
      <div className="divider"></div>
    </div>
    
    <div className="servicio-content">
        <div className="servicio-text">
            <h3>Servicio de Scanner:</h3>
            <br />
            <p>Contamos con la tecnología necesaria para ofrecer un servicio de scanner preciso y eficiente para detectar y solucionar problemas en el sistema electrónico de tu vehículo.</p>
        </div>
        <div className="servicio-image">
            <img src={servicioImg} alt="servicio" />
        </div>
    </div>
</section>
  )
}

export default Scanner