import React from 'react'

import servicioImg from '../../assets/servicio.jpg'

const Revision = () => {
  return (
    <section className='servicio'>
        <div className="servicio-head">
          <h2>nuestros servicios</h2>
          <div className="divider"></div>
        </div>
        
        <div className="servicio-content">
            <div className="servicio-text">
                <h3>Revisión y reparación de frenos para vehículos medianos:</h3>
                <br />
                <p>Contamos con un equipo altamente capacitado y la tecnología necesaria para realizar una revisión y reparación precisa de los frenos de tu vehículo mediano.</p>
            </div>
            <div className="servicio-image">
                <img src={servicioImg} alt="servicio" />
            </div>
        </div>
    </section>
  )
}

export default Revision