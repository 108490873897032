import './App.scss';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from "react-router-dom";

import Navbar from './components/Navbar/Navbar'
import Footer from './components/Footer/Footer'
import Home from './Pages/Home/Home'
import About from './Pages/About/About'
import Services from './Pages/Services/Services'
import Contact from './Pages/Contact/Contact'

import Alineacion from './Pages/Alineacion/Alineacion';
import Balanceo from './Pages/Balanceo/Balanceo';
import Vulcanizacion from './Pages/Vulcanizacion/Vulcanizacion';
import Discos from './Pages/Discos/Discos';
import Revision from './Pages/Revision/Revision';
import Rectificacion from './Pages/Rectificacion/Rectificacion';
import Aceite from './Pages/Aceite/Aceite';
import Scanner from './Pages/Scanner/Scanner';
import Hidraulicos from './Pages/Hidraulicos/Hidraulicos';

const Layout = ()=>{
  return(
    <div className='app'>
      <Navbar/>
      <Outlet/>
      <Footer/>
    </div>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout/>,
    children:[
      {
        path:"/",
        element:<Home/>
      },
      {
        path:"/about",
        element:<About/>
      },
      {
        path:"/services",
        element:<Services/>,
      },
      {
        path:"/contact",
        element:<Contact/>
      },
      {
        path:"/alineacion",
        element:<Alineacion/>
      },
      {
        path:"/balanceo",
        element:<Balanceo/>
      },
      {
        path:"/vulcanizacion",
        element:<Vulcanizacion/>
      },
      {
        path:"/discos",
        element:<Discos/>
      },
      {
        path:"/revision",
        element:<Revision/>
      },
      {
        path:"/rectificacion",
        element:<Rectificacion/>
      },
      {
        path:"/aceite",
        element:<Aceite/>
      },
      {
        path:"/scanner",
        element:<Scanner/>
      },
      {
        path:"/hidraulicos",
        element:<Hidraulicos/>
      },
      
    ],
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
