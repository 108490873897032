import React, { useState } from 'react';
import {Link} from 'react-router-dom'
import { HiMenu, HiX } from 'react-icons/hi';
import { motion } from 'framer-motion';

import './Navbar.scss'

import Logo from '../../assets/logo.png'

const Navbar = () => {

  const [toggle, setToggle] = useState(false);

  return (
    <div className='app__navbar'>

      <div className='app__navbar-logo'>
        <Link to="/"><img src={Logo} alt="logo" /></Link>
      </div>
      <ul className="app__navbar-links">
        <li><Link to="/" className='link'>inicio</Link></li>
        <li><Link to="/about" className='link'>sobre nosotros</Link></li>
        <li><Link to="/services" className='link'>servicios</Link></li>
        <li><Link to="/contact" className='link'>contacto</Link></li>
      </ul>

      <div className="app__navbar-menu">
        <HiMenu onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: 'easeOut' }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
             <li><Link to="/" className='link' onClick={() => setToggle(false)}>inicio</Link></li>
             <li><Link to="/about" className='link' onClick={() => setToggle(false)}>sobre nosotros</Link></li>
             <li><Link to="/services" className='link' onClick={() => setToggle(false)}>servicios</Link></li>
             <li><Link to="/contact" className='link' onClick={() => setToggle(false)}>contacto</Link></li>
            </ul>
          </motion.div>
        )}
      </div>

    </div>
  )
}

export default Navbar