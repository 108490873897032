import React from 'react'

import servicioImg from '../../assets/servicio.jpg'

const Aceite = () => {
  return (
    <section className='servicio'>
        <div className="servicio-head">
          <h2>nuestros servicios</h2>
          <div className="divider"></div>
        </div>
        
        <div className="servicio-content">
            <div className="servicio-text">
                <h3>Cambio de aceite para vehículos menores y mayores:</h3>
                <br />
                <p>Ofrecemos un servicio de cambio de aceite de alta calidad para vehículos menores y mayores, utilizando los mejores productos del mercado para garantizar un rendimiento óptimo del motor.</p>
            </div>
            <div className="servicio-image">
                <img src={servicioImg} alt="servicio" />
            </div>
        </div>
    </section>
  )
}

export default Aceite