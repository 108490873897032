import React from 'react'

import RoomIcon from '@mui/icons-material/Room';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

import './Contact.scss'

const Contact = () => {
  return (
    <section className='contact'>
      <div className="contact-head">
        <h2>contáctanos</h2>
        <div className='divider'></div>
      </div>

      <div className="contact__content">
        <div className="contact__content-map">
        <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3392.716221147699!2d-71.071242!3d-31.750945999999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzHCsDQ1JzAzLjQiUyA3McKwMDQnMTYuNSJX!5e0!3m2!1ses!2sar!4v1681837203976!5m2!1ses!2sar"  width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

        <div className='contact__content-form'>

          <div className="info">

            <div className="item">
              <span><RoomIcon/></span>
              <div className="item-info">
                <h3>dirección</h3>
                <p>Parcela 7 Lote 1 Chuchiñi - Salamanca</p>
              </div>
            </div>
            <div className="item">
              <span><EmailIcon/></span>
              <div className="item-info">
                <h3>email</h3>
                <p>contacto@fasttrackmecanica.cl</p>
              </div>
            </div>
            <div className="item">
              <span><LocalPhoneIcon/></span>
              <div className="item-info">
                <h3>telefono</h3>
                <p>+56 9 58555539</p>
              </div>
            </div>

          </div>

          <div className="form-container">
           <form action="https://formsubmit.co/contacto@fasttrackmecanica.cl" method="POST">
            <input type="text" name="name" placeholder='Tu nombre' required/>
            <input type="email" name="email" placeholder='Tu email' required/>
            <input type="text" name='asunto' placeholder='Asunto' required />
            <textarea name="message" id="message" cols="30" rows="10" placeholder='Deja tu mensaje'></textarea>
            <button type="submit">Send</button>
           </form>
          </div>

        </div>

      </div>

    </section>
  )
}

export default Contact
