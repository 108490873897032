import React from 'react'

import servicioImg from '../../assets/servicio.jpg'

const Rectificacion = () => {
  return (
    <section className='servicio'>
        <div className="servicio-head">
          <h2>nuestros servicios</h2>
          <div className="divider"></div>
        </div>
        
        <div className="servicio-content">
            <div className="servicio-text">
                <h3>Rectificación camber y coster:</h3>
                <br />
                <p>Ofrecemos servicios de rectificación camber y caster, los cuales son esenciales para garantizar la estabilidad y seguridad en la carretera, especialmente en vehículos modificados o de alto desempeño.</p>
            </div>
            <div className="servicio-image">
                <img src={servicioImg} alt="servicio" />
            </div>
        </div>
    </section>
  )
}

export default Rectificacion