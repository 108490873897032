import React from 'react'

import servicioImg from '../../assets/servicio.jpg'


const Hidraulicos = () => {
  return (
    <section className='servicio'>
    <div className="servicio-head">
      <h2>nuestros servicios</h2>
      <div className="divider"></div>
    </div>
    
    <div className="servicio-content">
        <div className="servicio-text">
            <h3>Cambio de flexibles hidráulicos:</h3>
            <br />
            <p>Realizamos un servicio de cambio de flexibles hidráulicos para garantizar la seguridad y durabilidad del sistema de frenos de tu vehículo.</p>
        </div>
        <div className="servicio-image">
            <img src={servicioImg} alt="servicio" />
        </div>
    </div>
</section>
  )
}

export default Hidraulicos