import React from 'react'

import aboutImg from '../../assets/about.jpg'
import camion from '../../assets/camion-tolva.png'


import './About.scss'

const About = () => {
  return (
    <section className='about'>

      <div className="about-head">
        <h2>sobre nosotros</h2>
        <div className="divider"></div>
        <span></span>
      </div>

      <div className="about-content">
        <div className="about-image">
          <img src={aboutImg} alt="about" />
        </div>
        <div className="about-text">
          <h2>¡Bienvenidos a <span>Fast Track</span>, tu centro de servicio automotriz innovador y audaz en la ciudad de Salamanca!</h2>
          <br />
          <p><b>Misión:</b> Ofrecer servicios de mantenimiento y reparación automotriz de alta calidad, utilizando la tecnología más avanzada y el mejor equipo de trabajo, para satisfacer las necesidades de nuestros clientes y asegurar su tranquilidad en la carretera.</p>
          <br />
          <p><b>Visión:</b> Ser líderes en el mercado automotriz local, ofreciendo soluciones eficientes y tecnológicas de última generación, lo que nos permitirá ser la mejor opción en servicios automotrices.</p>
          <br />
          <h3>¿Por qué elegirnos? </h3>
        <p>En <b>Fast Track</b>, nos diferenciamos por nuestra propuesta de valor única: la combinación perfecta entre tecnología y calidad total. Nos aseguramos de utilizar la mejor tecnología disponible en el mercado, lo que nos permite ofrecer servicios de diagnóstico y reparación más precisos y eficientes, tanto para vehículos menores, como para camiones y buses. Además, nuestro equipo de trabajo altamente calificado está en constante capacitación, lo que nos permite estar al día con las últimas tendencias del mercado.</p>
        <br />
        </div>
      </div>
      <div className="about-content-2">
        <div className="about-text-2">
        <p>
        Además, en <b>Fast Track</b> ofrecemos un servicio personalizado y de alta calidad en la ciudad de Salamanca. Todo esto nos convierte en una opción única para nuestros clientes.
        </p>
        <br />
        <p>En resumen, en <b>Fast Track</b> nos enfocamos en ofrecer soluciones innovadoras y audaces para resolver los problemas principales del mercado automotriz local, garantizando la satisfacción y seguridad de nuestros clientes. ¡Contáctanos para agendar una cita y experimentar la diferencia en el servicio automotriz!</p>

        </div>
        <div className="about-image">
          <img src={camion} alt="about" />
        </div>
      </div>

    </section>
  )
}

export default About
