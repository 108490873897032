import React from 'react'
import Header from '../../components/Header/Header'
import Logo2 from '../../assets/logo-2.png'


import './Home.scss'

const Home = () => {
  return (
    <section className='home'>
      <Header/>
      <div className="elegirnos">
        
        <div className="elegirnos-content">
         <div className="elegirnos-image">
          <img src={Logo2} alt="logo" />
         </div>
         <div className="elegirnos-text">
          <h2>por que elegirnos?</h2>
          <p>En <b>Fast Track</b>, nos diferenciamos por nuestra propuesta de valor única: la combinación perfecta entre tecnología y calidad total. Nos aseguramos de utilizar la mejor tecnología disponible en el mercado, lo que nos permite ofrecer servicios de diagnóstico y reparación más precisos y eficientes, tanto para vehículos menores, como para camiones y buses. Además, nuestro equipo de trabajo altamente calificado está en constante capacitación, lo que nos permite estar al día con las últimas tendencias del mercado.</p>
          
         </div>
        </div>
        <div className='elegirnos-bg'></div>
      </div>
    </section>
  )
}

export default Home