import React from 'react'

import servicioImg from '../../assets/servicio.jpg'

const Discos = () => {
  return (
    <section className='servicio'>
        <div className="servicio-head">
          <h2>nuestros servicios</h2>
          <div className="divider"></div>
        </div>
        
        <div className="servicio-content">
            <div className="servicio-text">
                <h3>Rectificación de discos y tambor para vehículos medianos:</h3>
                <br />
                <p>Contamos con la tecnología necesaria para ofrecer servicios de rectificación de discos y tambor, para una mayor eficiencia en el sistema de frenos de tu vehículo.</p>
            </div>
            <div className="servicio-image">
                <img src={servicioImg} alt="servicio" />
            </div>
        </div>
    </section>
  )
}

export default Discos