import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../assets/logo-2.png'

import './Footer.scss'

const Footer = () => {
  return (
    <footer className='footer'>
      <div className="top">
        <div className="item" id='item-1'>
           <Link to="/"><img src={Logo} alt="logo" /></Link>
        </div>
        <div className="item">
          <h3>Nuestros servicios</h3>
           <ul>
            <li><Link to="/alineacion" className='link'>Alineación vehículos menores, camiones y buses</Link></li>
            <li><Link to="/balanceo" className='link'>Balanceo vehículos menores, camiones y buses</Link></li>
            <li><Link to="/vulcanizacion" className='link'>Servicio de Vulcanización para vehículos menores y mayores</Link></li>
            <li><Link to="/discos" className='link'>Rectificación de discos y tambor para vehículos medianos</Link></li>
            <li><Link to="/revision" className='link'>Revisión y reparación de frenos para vehículos medianos</Link></li>
            <li><Link to="/rectificacion" className='link'>Rectificación camber y coster</Link></li>
            <li><Link to="/aceite" className='link'>Cambio de aceite para vehículos menores y mayores</Link></li>
            <li><Link to="/scanner" className='link'>Servicio de Scanner</Link></li>
            <li><Link to="/hidraulicos" className='link'>Cambio de flexibles hidráulicos</Link></li>
          </ul>
        </div>
        <div className="item">
          <h3>Contáctanos</h3>
           <p>Parcela 7 Lote 1 Chuchiñi - Salamanca</p>
           <p>+56 9 58555539</p>
          <button className='btn'><Link id='link' to="/contact">Reserva tu turno</Link></button>
        </div>
      </div>
      <div className="bottom">
        <span></span>
        <p><b>Todos los derechos reservados.</b> Fast Track 2023 ®</p>
      </div>
    </footer>
  )
}

export default Footer
